
import modalMixin from "@/mixins/modal"
import type { Session } from "@/types/api/program"

export default modalMixin("open-session").extend({
  data: () => ({
    session: null as Nullable<Session>,
  }),

  methods: {
    open (session: Session) {
      if (!session) return
      this.session = session
      this.isOpen = true
      this.setUrl(`/program/${session.alias}`)
    },
  },
})
