// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@media(max-width:667.98px){#speaker-modal .el-dialog__wrapper{display:flex;flex-direction:column;justify-content:flex-end}#speaker-modal .el-dialog{border-bottom-left-radius:0;border-bottom-right-radius:0;margin-bottom:0;margin-top:var(--spacing-sm)!important;overflow-y:auto}#speaker-modal .el-dialog__body{--padding-x:var(--spacing-sm);padding-left:var(--padding-x);padding-right:var(--padding-x)}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xs": "667.98",
	"sm": "947.98",
	"md": "1023.98",
	"lg": "1229.98"
};
module.exports = ___CSS_LOADER_EXPORT___;
