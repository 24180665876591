
import modalMixin from "@/mixins/modal"
import type { Person } from "@/types/api/person"

export default modalMixin("open-speaker").extend({
  data: () => ({
    speaker: null as Nullable<Person>,
  }),

  methods: {
    open (speaker: Person) {
      if (!speaker) return
      this.speaker = speaker
      this.isOpen = true
      this.setUrl(`/speakers/${speaker.alias}`)
    },
  },
})
