import Vue from "vue"
import { changeUrl } from "@/utils/_"

const mixin = (eventName: string) => Vue.extend({
  data: () => ({
    isOpen: false,
    oldUrl: "",
  }),

  mounted () { this.$nuxt.$on(eventName, this.open) },
  beforeDestroy () { this.$nuxt.$off(eventName, this.open) },

  methods: {
    open () {}, // Определить в компоненте

    setUrl (path: string) {
      this.oldUrl = window.location.href
      changeUrl(this.localePath(path))
    },
    restoreUrl () { changeUrl(this.oldUrl || this.$route.fullPath) },

    close () {
      this.isOpen = false
      this.restoreUrl()
    },

    beforeClose (done: Function) {
      this.restoreUrl()
      done()
    },
  },
})
export default mixin
